import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { LdkGraphqlApiService, PdfExportOptions } from '@lumosa/ui-sdk/services';
import { loadStripe, Stripe, StripeElements, StripePaymentElement } from '@stripe/stripe-js';
import { tap, lastValueFrom, map, take, switchMap, delay, interval, takeUntil, Subject } from 'rxjs';
import { GQL_MUTATION_GET_AUTHORIZE_TOKEN, GQL_MUTATION_PAYMENT_INTENT } from 'src/app/graphql/gql.mutations';
import { GQL_QUERY_RUNNING_TRANSACTION_FOR_TAG, GQL_QUERY_TRANSACTION_COSTS, GQL_QUERY_TRANSACTION_STATUS } from 'src/app/graphql/gql.queries';
import { Payment } from 'src/app/models/tariff.model';
import { StateService } from 'src/app/services/state.service';
import { IMAGE_CHARGING, IMAGE_MONEY, IMAGE_START, IMAGE_STOP } from '../home/images';
import { environment } from 'src/environments/environment';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import * as uuid from 'uuid';
import { LdkDialogConfirmComponent } from '@lumosa/ui-sdk/components/dialogs';
import { InvoiceService } from 'src/app/services/invoice.service';

const TIMEOUT_WAIT_START_OR_STOP = 10000;   // 10s
const INTERVAL_CHECK_COSTS = 3 * 60000;     // 3 min
const INTERVAL_CHECK_STATUS = 1 * 60000;    // 1 min

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  @Input() set selected(tabSelected: boolean) {
    if(tabSelected) {
      if(this.state.transaction.isRunning && !this.state.transaction.isCompleted) {
        this.updateTransactionStatusOnce().subscribe();
        this.updateTransactionStatusPeriodically().subscribe();
        this.updateTransactionCostsOnce().subscribe();
        this.updateTransactionCostsPeriodically().subscribe();
      }

      if (!this.state.transaction.id && !this.state.payment.id) {
        this.state.isLoading = true;
        this.createPaymentIntent()
          .then(x => this.state.payment = x)
          .catch(e => {
            this.state.isLoading = false;
            console.log("could not create a payment intent", e)
            // TODO show a popup? 
          });
      }
    }
  }

  stop$ = new Subject<void>();

  isLoadingStripeElements = true;

  imageStart = IMAGE_START;
  imageStop = IMAGE_STOP;
  imageMoney = IMAGE_MONEY;
  imageUsage = IMAGE_CHARGING;

  private stripe!: Stripe | null;
  private elements!: StripeElements | undefined;

  constructor(
    public state: StateService,
    private graphqlApi: LdkGraphqlApiService,
    private http: HttpClient,
    private dialogService: NbDialogService,
    private notify: NbToastrService,
    private invoice: InvoiceService
  ) { }

  async ngOnInit(): Promise<void> {
    this.stripe = await loadStripe(environment.token.stripe);
  }

  private createPaymentIntent(): Promise<Payment> {
    const o$ = this.graphqlApi.mutation<Payment>({
      mutation: GQL_MUTATION_PAYMENT_INTENT,
      variables: {
        input: {
          amount: this.state.selectedAmount * 100,
          currency: this.state.tariff.unit,
          paymentMethodTypes: environment.config.stripe.paymentTypes || [ 'card' ]
        }
      },
      returnedAsName: 'intent'
    })
    .pipe(
      tap(async result => {
        const appearance = { /* appearance */ };
        const options = {
          layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false
          }
        };

        const { clientSecret, id } = result;
        this.state.payment.clientSecret = clientSecret;
        this.state.payment.id = id;

        this.elements = this.stripe?.elements({ clientSecret, appearance });
        const paymentElement: StripePaymentElement = (<any>this.elements?.create)('payment', options);
        paymentElement.on('ready', () => {
          this.isLoadingStripeElements = false;
          this.state.isLoading = false;
        });
        paymentElement.mount('#payment-element');
      })
    );
    return lastValueFrom(o$);
  }

  private async confirmPaymentIntent() {
    const result = await this.stripe?.confirmPayment({
      elements: this.elements,
      clientSecret: '',
      redirect: 'if_required',
      confirmParams: {
        // return_url: 'http://localhost:8100/checkout',
        receipt_email: "luis@lumosa.eu",
      }
    });

    const error = result?.error;
    if (error?.type === "card_error" || error?.type === "validation_error") {
      throw { error: { message: error.message }};

    } else if (error) {
      throw { error: { message: error.message }};
    }

    return true;
  }

  private createPaymentToken() {
    const o$ = this.graphqlApi.mutation<string>({
      mutation: GQL_MUTATION_GET_AUTHORIZE_TOKEN,
      variables: { 
        input: {
          chargerId: this.state.chargerForm.value.name, 
          stripePaymentId: this.state.payment.id,
          email: this.state.credentialsForm.value.email,
          pincode: this.state.credentialsForm.value.pincode?.join('')
        }
      },
      returnedAsName: 'token'
    });
    return lastValueFrom(o$);
  }

  private ocppRemoteStart() {
    const { name: chargerId, connectorId } = this.state.chargerForm.value;
    const o$ = this.http.post(`${environment.url.cpo}/webhook/ocpp/RemoteStartTransaction?token=${environment.token.cpo}`, {
      chargerId,
      payload:[
        2,
        uuid.v4(),
        "RemoteStartTransaction",
        { 
          idTag: this.state.transaction.tagId,
          connectorId: +(connectorId ?? '1')
        }
      ]
    }).pipe(
      map((x: any) => {
        if (x.payload?.status !== 'Accepted') {
          throw { error: { message: 'Could not start remote transaction. Rejected by Operator' }};
        }
        return x;
      }),
      delay(TIMEOUT_WAIT_START_OR_STOP),
      switchMap(_ => this.graphqlApi.query<any[]>({
        query: GQL_QUERY_RUNNING_TRANSACTION_FOR_TAG,
        variables: {
          chargerId,
          tagId: this.state.transaction.tagId
        },
        returnedAsName: 'transactions'
      })),
      take(1),
      map(x => {
        if (x?.length > 0) {
          return x[0];
        }
        return null;
      })
    )
    return lastValueFrom(o$);
  }

  private ocppRemoteStop() {
    const { name: chargerId } = this.state.chargerForm.value;
    const o$ = this.http.post(`${environment.url.cpo}/webhook/ocpp/RemoteStopTransaction?token=${environment.token.cpo}`, {
      chargerId,
      payload:[
        2,
        uuid.v4(),
        "RemoteStopTransaction",
        { 
          transactionId: this.state.transaction.id
        }
      ]
    }).pipe(
      map((x: any) => {
        if (x.payload?.status !== 'Accepted') {
          throw { error: { message: 'Could not stop remote transaction. Rejected by Operator' }};
        }
        return x;
      }),
      delay(TIMEOUT_WAIT_START_OR_STOP),
      switchMap(_ => this.graphqlApi.query<any[]>({
        query: GQL_QUERY_TRANSACTION_STATUS,
        variables: {
          transactionId: this.state.transaction.id
        },
        returnedAsName: 'transactions'
      })),
      take(1),
      map(x => {
        if (x?.length > 0) {
          const info = x[0];
          const consumptionKwh = info.meterStop - info.meterStart;
          this.state.transaction.isCompleted = info.completed;
          this.state.transaction.endDate = info.endDate;
          this.state.transaction.consumptionKwh = consumptionKwh > 0 ? consumptionKwh : 0;
          return x[0].completed;
        }
        return false;
      })
    )
    return lastValueFrom(o$);
  }

  private updateTransactionStatusPeriodically() {
    return interval(INTERVAL_CHECK_STATUS)
      .pipe(
        takeUntil(this.stop$),
        switchMap(_ => this.updateTransactionStatusOnce())
      );
  }

  private updateTransactionStatusOnce() {
    return this.graphqlApi.query<any[]>({
      fetchPolicy: 'no-cache',
      query: GQL_QUERY_TRANSACTION_STATUS,
      variables: {
        transactionId: this.state.transaction.id
      },
      returnedAsName: 'transactions'
    })
    .pipe(
      take(1),
      map(x => {
        if (x?.length > 0) {
          const info = x[0];
          const consumptionKwh = info.meterStop - info.meterStart;
          this.state.transaction.isCompleted = info.completed;
          this.state.transaction.endDate = info.endDate;
          this.state.transaction.consumptionKwh = consumptionKwh > 0 ? consumptionKwh : 0;
          return x[0].completed;
        }
        return false;
      }),
      tap(completed => {
        if (completed) {
          this.stop$.next();
          this.stop$.complete();
        }
      })
    )
  }

  private updateTransactionCostsPeriodically() {
    return interval(INTERVAL_CHECK_COSTS)
      .pipe(
        takeUntil(this.stop$),
        switchMap(_ => this.updateTransactionCostsOnce()),
      );
  }

  private updateTransactionCostsOnce() {
    return this.graphqlApi.query<{ costs: number, amountCapturable: number, consumptionKwh: number, unit: string}>({
      fetchPolicy: 'no-cache',
      query: GQL_QUERY_TRANSACTION_COSTS,
      variables: {
        transactionId: this.state.transaction.id
      },
      returnedAsName: 'costs'
    })
    .pipe(
      take(1),
      tap(async x => {
        this.state.transaction.consumptionKwh = x.consumptionKwh;
        this.state.transaction.costs = `${x.costs} ${x.unit}`;
        console.log(x, x.costs > x.amountCapturable);
        if (x.costs > x.amountCapturable) {
          await this.stopTransaction();
        }
      })
    )
  }

  async startTransaction() {
    try {
      this.state.isLoading = true;

      if (!this.state.payment.approved) {
        // Get payment approval from stripe. 
        this.state.payment.approved = await this.confirmPaymentIntent();
        // Store payment id and return a valid TAG token to perform a charging session. 
        const token = await this.createPaymentToken();
        this.state.transaction.tagId = token;
      }
      // Send remote start with token. Wait for confirmation that transaction is running.
      const transactionInfo = await this.ocppRemoteStart();
      if (!transactionInfo) {
        throw { error: { message: 'Could not remote start the session'} };
      } 

      this.state.transaction = {
        ...this.state.transaction,
        id: transactionInfo.transactionId,
        startDate: transactionInfo.startDate,
        tagId: transactionInfo.tagId,
        isRunning: true,
        costs: `${this.state.tariff.startFee + this.state.tariff.parkingFee} ${this.state.tariff.unit}`
      }

      this.updateTransactionStatusPeriodically().subscribe();
      this.updateTransactionCostsPeriodically().subscribe();

    } catch (data: any) {
      this.state.isLoading = false;
      if (this.state.payment.id && this.state.payment.approved) {
        // if fails, marks payment that has to be canceled in case user leaves payment page
        this.state.paymentIdCancel = this.state.payment.id;
      }
      const d$ = this.dialogService
        .open(LdkDialogConfirmComponent, {
          context: {
            title: 'Warning',
            message: data.error.message,
            buttonText: {
              ok: 'Ok',
              cancel: ''
            }
          },
        })
        .onClose;
      await lastValueFrom(d$);

    } finally {
      this.state.isLoading = false;
    }
  }

  async stopTransaction() {
    try {
      this.state.isLoading = true;
      await this.ocppRemoteStop();
      this.stop$.next();
      this.stop$.complete();
      await lastValueFrom(this.updateTransactionCostsOnce());

    } catch (data: any) {
      this.state.isLoading = false;
      const d$ = this.dialogService
        .open(LdkDialogConfirmComponent, {
          context: {
            title: 'Warning',
            message: data.error.message,
            buttonText: {
              ok: 'Ok',
              cancel: ''
            }
          },
        })
        .onClose;
      await lastValueFrom(d$);

    } finally {
      this.state.isLoading = false;
    }
  }

  refresh() {
    this.updateTransactionCostsOnce().subscribe();
    this.updateTransactionStatusOnce().subscribe();
  }

  createInvoice() {
    const startFee = `${this.state.tariff.startFee} ${this.state.tariff.unit}`;
    const parkingFee = `${this.state.tariff.parkingFee} ${this.state.tariff.unit}`;
    const kwhFee = `${this.state.tariff.kwhFee} ${this.state.tariff.unit}`;
    const kwhFeeTotal = `${this.state.tariff.kwhFee * this.state.transaction.consumptionKwh} ${this.state.tariff.unit}`;
    const fees: any[] = [
      { Fee: 'Start Fee', Price: startFee, Total: startFee },
      { Fee: 'Parking Fee', Price: parkingFee, Total: parkingFee },
      { Fee: 'Price kWh', Price: kwhFee, Quantity: `${this.state.transaction.consumptionKwh} kWh`, Total: kwhFeeTotal}
    ];
    const options: PdfExportOptions = {
      exportedFields: ['Fee', 'Price', 'Quantity', 'Total'],
      fileName: `invoice-${this.state.payment.id}.pdf`
    }
    this.invoice.export(fees, options);
  }
}
