import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbStepperComponent, NbToastrService } from '@nebular/theme';
import { TariffComponent } from '../tariff/tariff.component';
import { delay, lastValueFrom, map, take, tap, catchError, of, EMPTY } from 'rxjs';
import { LdkGraphqlApiService } from '@lumosa/ui-sdk/services';
import { LdkDialogConfirmComponent } from '@lumosa/ui-sdk/components/dialogs';
import { CredentialsComponent } from '../credentials/credentials.component';
import { StateService } from 'src/app/services/state.service';
import { GQL_QUERY_CHARGER_INFO, GQL_QUERY_TARIFFS, GQL_QUERY_RUNNING_TRANSACTION_FOR_CHARGER_AND_CONNECTOR, GQL_QUERY_RUNNING_TRANSACTION_BELONGS_TO_USER } from 'src/app/graphql/gql.queries';
import { Tariff, DEFAULT_TARIFF } from 'src/app/models/tariff.model';
import { GQL_MUTATION_CANCEL_PAYMENT } from 'src/app/graphql/gql.mutations';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('stepper') stepper!: NbStepperComponent;
  @ViewChild('credentials') credentials!: CredentialsComponent;
  @ViewChild('tariff') tariff!: TariffComponent;

  isPublicCharger = false;

  constructor(
    public state: StateService,
    private graphqlApi: LdkGraphqlApiService,
    private dialogService: NbDialogService,
    private notify: NbToastrService
  ) { }

  async ngOnInit() {
    this.graphqlApi.errors$
      .pipe(
        tap(error => {
          const { code, message } = error;
          if (HttpStatusCode.Unauthorized === code || HttpStatusCode.Forbidden === code) {
            this.state.credentialsForm.patchValue({ pincode: ['', '', '', '', '', '']});
            this.stepper.reset();
            this.notify.warning(`Your session has expired`, 'Warning');

          } else {
            this.notify.warning(message, 'Warning');
          }
        })
      )
      .subscribe();
  }

  async goToStepCharger() {
    const sessionToken = await this.credentials.requestApiToken();
    if (sessionToken) {
      this.state.sessionToken = sessionToken;
      await this.cancelPendingPaymentsNoLongerInUse();
      await this.loadChargerInfo();
      this.stepper.next();
      
    } else {
      this.notify.warning('Your pincode is invalid');
    }
  }
  
  private async loadChargerInfo() {
    const o$ = this.graphqlApi.query<any[]>({
      query: GQL_QUERY_CHARGER_INFO,
      variables: { typePlateId: this.state.chargerForm.value.typeplate },
      returnedAsName: 'chargers'
    })
    .pipe(
      tap(_ => this.state.isLoading = true),
      take(1),
      tap(x => {
        if (x?.length > 0) {
          const { address, name, isPublic } = x[0];
          this.isPublicCharger = isPublic;
          this.state.chargerForm.patchValue({
            name,
            address: `${address.street}, ${address.zipcode} ${address.city}, ${address.country}`
          });
        } else {
          this.state.chargerForm.patchValue({
            name: '',
            address: ''
          });
          throw 'No charger found';
        }
      }),
      delay(200),
      catchError(e => {
        return of(EMPTY);
      }),
      tap(_ => this.state.isLoading = false)
    );
    return lastValueFrom(o$);
  }

  private async cancelPendingPaymentsNoLongerInUse() {
    const cancelPendingPayment = this.state.paymentIdCancel;
    // assure an accepted payment that did not start a transaction is cancelled.
    if (!!cancelPendingPayment) {
      const o$ = this.graphqlApi.mutation({
        mutation: GQL_MUTATION_CANCEL_PAYMENT,
        variables: { paymentId: cancelPendingPayment },
        returnedAsName: 'status'
      }).pipe(
        take(1)
      );
      try {
        await lastValueFrom(o$);
      } catch (error) {
        // ignore if payment id no longer exists
      } finally {
        this.state.paymentIdCancel = null;
      }
    }
  }

  async goToStepTariff() {
    this.state.isLoading = true;
    try {
      const chargerSession = await this.getChargerSession();
      if (chargerSession) {
        const tariff: Tariff = chargerSession.payment;
        this.tariff.updateTariffOverview(tariff);
  
      } else {
        const tariff = await this.getChargerTariff();
        this.tariff.updateTariffOverview(tariff);
      }
      this.state.isLoading = false;
      this.stepper.next();

    } catch (error) {
      this.state.isLoading = false;
    }
  }

  async goToStepSession() {
    // if no transaction running, proceed to credit card payment
    if (!this.state.transaction.id) {
      this.stepper.next();
      return;
    }

    // if transaction running, check if user input correct credentials.
    try {
      const hasPermissions = await this.validateChargerTransactionCredentials();
      if (!hasPermissions) {
        throw { error: { message: 'You do not have permissions to view or stop charger session. Please input correct email and pincode combination' }};
      }

    } catch (data: any) {
      const d$ = this.dialogService
        .open(LdkDialogConfirmComponent, {
          context: {
            title: 'Warning',
            message: data.error.message,
            buttonText: {
              ok: 'Ok',
              cancel: ''
            }
          },
        })
        .onClose;
      await lastValueFrom(d$);      
      return;
    }

    // if valid credentials follow to next step
    this.stepper.next();
  }

  private async getChargerTariff() {
    const o$ = this.graphqlApi.query<Tariff[]>({
      query: GQL_QUERY_TARIFFS,
      variables: { 
        chargerId: this.state.chargerForm.value.name
      },
      returnedAsName: 'tariffs'
    })
    .pipe(
      take(1),
      delay(200),
      map(x => {
        let tariff = DEFAULT_TARIFF;
        if (x.length > 0) {
          tariff = x[0];
        }
        return tariff;
      })
    )
    return lastValueFrom(o$);
  }

  private async getChargerSession() {
    const o$ = this.graphqlApi.query<any[]>({
      query: GQL_QUERY_RUNNING_TRANSACTION_FOR_CHARGER_AND_CONNECTOR,
      variables: {
        chargerId: this.state.chargerForm.value.name,
        connectorId: +(this.state.chargerForm.value.connectorId || 1)
      },
      returnedAsName: 'transactions'
    }).pipe(
      take(1),
      delay(200),
      tap(x => {
        if (x?.length > 0) {
          const value = x[0];
          this.state.transaction = {
            ...this.state.transaction,
            id: value.transactionId,
            isRunning: true,
            tagId: value.tagId,
            startDate: value.startDate
          }
        } else {
          this.state.transaction = {
            ...this.state.transaction,
            id: null,
            isRunning: false,
            tagId: '',
            startDate: '',
            endDate: '',
            isCompleted: false
          }
        }
      }),
      map(x => {
        if (x.length > 0) {
          return x[0];
        }
        return null;
      })
    )
    return lastValueFrom(o$);
  }

  private async validateChargerTransactionCredentials() {
    const o$ = this.graphqlApi.query<any[]>({
      query: GQL_QUERY_RUNNING_TRANSACTION_BELONGS_TO_USER,
      variables: {
        transactionId: this.state.transaction.id,
        email: this.state.credentialsForm.value.email,
        pincode: (this.state.credentialsForm.value.pincode || []).join('')
      },
      returnedAsName: 'transactions'
    }).pipe(
      take(1),
      map(x => x?.length > 0)
    )
    return lastValueFrom(o$);
  }
}
