<nb-layout>
  <nb-layout-header fixed>
      <img src="/assets/images/lumosa-logo-3.png">
      <div class="filler"></div>
      <p class="title"><b>Direct pay</b></p>
  </nb-layout-header>
  <nb-layout-column style="padding: 0px;">
    <div style="width: 100%; height: 2px; background-color: #00FF0022;"></div>
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>