<nb-card *ngIf="!state.chargerForm.value.name">
    <ldk-notfound-card
        title="Not Found"
        description="charger with serial number {{ state.chargerForm.value.typeplate }} not found in our records"
        [buttonVisible]="false">
    </ldk-notfound-card>
</nb-card>

<ng-container *ngIf="state.chargerForm.value.name">
    <label class="label">Charger</label>
    <nb-card>
        <nb-list>
            <nb-list-item>
                <nb-user
                    shape="semi-round"
                    title="{{ state.chargerForm.value.connectorId }}"
                    name="{{ state.chargerForm.value.name | uppercase }}"
                    [picture]="images.charger">
                </nb-user>
            </nb-list-item>
            <nb-list-item *ngIf="state.chargerForm.value.address" (click)="goToMap()">
                <nb-user
                    shape="semi-round"
                    name="{{ state.chargerForm.value.address }}"
                    [picture]="images.location">
                </nb-user>
            </nb-list-item>
        </nb-list>
    </nb-card>
    <nb-card *ngIf="!isPublicCharger" status="danger">
        <nb-card-header>
            Direct Pay can not be used because this charger is not set as public
        </nb-card-header>
    </nb-card>
</ng-container>
