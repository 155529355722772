import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from './state.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private state: StateService
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        ['x-direct-pay']: this.state.sessionToken
      },
    });
    return next.handle(request);
  }
}
