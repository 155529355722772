<nb-card style="height: 100%;" [nbSpinner]="state.isLoading">
    <nb-card-body style="padding: 0px; padding-top: 10px;">
        <nb-stepper 
            #stepper 
            orientation="horizontal" 
            linear="true">
                <nb-step label="User">
                    <app-credentials 
                        #credentials
                        [form]="state.credentialsForm">
                    </app-credentials>
                    <button 
                        nbButton 
                        status="success" 
                        (click)="goToStepCharger()" 
                        [disabled]="!state.credentialsForm.valid">
                            Next
                    </button>
                </nb-step>

                <nb-step label="Charger">
                    <app-charger
                        [isPublicCharger]="isPublicCharger"
                        #charger>
                    </app-charger>
                    <button
                        *ngIf="isPublicCharger"
                        nbButton 
                        status="success" 
                        (click)="goToStepTariff()" 
                        [disabled]="!state.chargerForm.valid">
                            Next
                    </button>
                </nb-step>

                <nb-step label="Tariff">
                    <app-tariff 
                        #tariff>
                    </app-tariff>
                    <button nbButton status="success" nbStepperNext>Next</button>
                </nb-step>

                <nb-step label="Session">
                    <app-payment 
                        #payment 
                        [selected]="stepper.selectedIndex === 3">
                    </app-payment>
                </nb-step>
        </nb-stepper>
    </nb-card-body>
</nb-card>
