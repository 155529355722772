<label class="label">User</label>
<nb-card>
    <nb-card-body>
        <form [formGroup]="form">
            <p>Input your charge session credentials</p>
            <label class="label">Email address</label>
            <input nbInput fullWidth formControlName="email" style="margin-bottom: 10px;">
            <nb-checkbox
                [checked]="rememberMe"
                [disabled]="!state.credentialsForm.value.email"
                (checkedChange)="rememberUser($event)">
                    Remember me
            </nb-checkbox>
            <p></p>
            <label class="label">Pin code</label>
            <div #pincodeContainer class="pincode-container">
                <ng-container *ngFor="let index of pincodeDigits">
                    <ng-template [ngTemplateOutlet]="pincode" [ngTemplateOutletContext]="{ index, pincode: form.value.pincode }"></ng-template>
                </ng-container>
            </div>
            <a 
                *ngIf="state.credentialsForm.value.email"
                href="javascript:void(null);" 
                (click)="requestPincode()">
                    I don't have a pincode yet
            </a>
        </form>
    </nb-card-body>
</nb-card>

<ng-template #pincode let-index="index" let-pincode="pincode">
    <input
        class="pincode-item"
        #pincodeItem
        nbInput 
        fullWidth 
        type="number"
        [min]="0" 
        [max]="9" 
        [maxLength]="1"
        pattern="[0-9]{1}"
        [value]="pincode[index] || ''"
        (focus)="onDigitFocus($event, index)"
        (keyup)="onDigitKeyup($event, index)">
</ng-template>