import { Injectable } from "@angular/core";
import { DEFAULT_TARIFF, Payment, Tariff } from "../models/tariff.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { pincodeValidator } from "../components/credentials/pincode.validator";
import { environment } from "../../environments/environment";
import { PINCODE_DIGIT_COUNT } from "../components/credentials/credentials.component";

export const STORE_KEY_USER_EMAIL = 'email';
export const STORE_KEY_USER_REMEMBER_ME = 'rememberMe';
export const STORE_PAYMENT_ID_CANCEL_PENDING = 'cancelPaymentId';

@Injectable({
    providedIn: "root"
})
export class StateService {
    constructor(
        private fb: FormBuilder
    ) {
        let email = '';
        if ((localStorage.getItem(STORE_KEY_USER_REMEMBER_ME) || 'false') === 'true') {
            email = localStorage.getItem(STORE_KEY_USER_EMAIL) || '';
        }
        
        this.chargerForm = this.fb.group({
            name: [ '', [Validators.required] ],
            connectorId: ['1', [Validators.required, Validators.pattern(/[1-2]{1}/)] ],
            typeplate: ['', [Validators.required] ],
            address: ['', [Validators.required] ]
        });
        
        this.credentialsForm = this.fb.group({
            email: [ email, [Validators.email, Validators.required] ],
            pincode: [new Array(PINCODE_DIGIT_COUNT).fill(''), [pincodeValidator()]]
        });
    }

    isLoading = false;

    sessionToken: string = "";

    set paymentIdCancel(value: string | null) {
        if (!!value) {
            localStorage.setItem(STORE_PAYMENT_ID_CANCEL_PENDING, value);
            
        } else {
            localStorage.removeItem(STORE_PAYMENT_ID_CANCEL_PENDING);
        }
    }

    get paymentIdCancel(): string {
        return localStorage.getItem(STORE_PAYMENT_ID_CANCEL_PENDING) || '';
    }

    selectedAmount = environment.config.stripe.reserve.amount;

    chargerForm!: FormGroup;

    credentialsForm!: FormGroup;

    tariff: Tariff = DEFAULT_TARIFF;

    payment: Payment = {
        clientSecret: '',
        id: '',
        approved: false
    };

    transaction: ChargerTransactionInfo = {
        id: null,
        tagId: '',
        isRunning: false,
        isCompleted: false,
        startDate: '',
        endDate: '',
        consumptionKwh: 0,
        costs: '0 EURO'
    }
}

export interface ChargerTransactionInfo {
    id: number | null;
    tagId: string, 
    isRunning: boolean;
    isCompleted: boolean;
    startDate: string;
    endDate: string;
    consumptionKwh: number;
    costs: string;
}