import { gql } from "apollo-angular";

export const GQL_MUTATION_PAYMENT_INTENT = gql`
mutation createPaymentIntent($input: CreatePaymentIntentInputParamsInput!) {
    intent: createPaymentIntent(input: $input) {
        id,
        clientSecret
    }
}
`;

export const GQL_MUTATION_GET_AUTHORIZE_TOKEN = gql`
mutation createPaymentAuthorizationToken($input: OcppPaymentInputParamsInput!) {
    token: createPaymentAuthorizationToken(input: $input)
}
`;

export const GQL_MUTATION_CANCEL_PAYMENT = gql`
mutation cancelPayment($paymentId: String!){
    status: cancelPayment(paymentId: $paymentId)
}
`;