import { gql } from "apollo-angular";

export const GQL_QUERY_DIRECTPAY_SESSION_TOKEN = gql`
query requestDirectPaySessionToken($connectorId: Byte!, $typePlateId: String!, $email: String!, $pincode: String!) {
  token: requestDirectPaySessionToken(
    typePlateId: $typePlateId
    email: $email
    pincode: $pincode,
    connectorId: $connectorId
  )
}
`;

export const GQL_QUERY_DIRECTPAY_PINCODE = gql`
query requestDirectPayPincode($connectorId: Byte!, $typePlateId: String!, $email: String!) {
  token: requestDirectPayPincode(
    typePlateId: $typePlateId
    email: $email
    connectorId: $connectorId
  )
}
`;

export const GQL_QUERY_TARIFFS = gql`
query chargerTariff($chargerId: String) {
    tariffs: chargerTariff(where: { chargerId: { eq: $chargerId } }) {
        chargerId
        unit
        kwhFee
        parkingFee
        startFee
        idleFee
    }
}
`;

export const GQL_QUERY_CHARGER_INFO = gql`
query chargers($typePlateId: String!) {
  chargers(
    where: {
      and: [
        { typePlateId: { startsWith: $typePlateId } },
        { typePlateId: { endsWith: $typePlateId } }
      ]
    }
  ) {
    name
    isPublic
    address {
      street
      zipcode
      city
      country
    }
  }
}
`;

export const GQL_QUERY_RUNNING_TRANSACTION_FOR_TAG = gql`
query transactions($chargerId: String!, $tagId: String!) {
  transactions(
    where: {
      and: [
        { chargerId: { eq: $chargerId } }, 
        { tagId: { eq: $tagId } },
        { completed: { eq: false } }
      ]
    }
  ) {
    transactionId,
    tagId,
    startDate
  }
}
`;

export const GQL_QUERY_RUNNING_TRANSACTION_FOR_CHARGER_AND_CONNECTOR = gql`
query transactions($chargerId: String!, $connectorId: Byte!) {
  transactions(
    where: {
      and: [
        { connectorId: { eq: $connectorId }}
        { completed: { eq: false }}
        { chargerId: { eq: $chargerId } }
      ]
    }
  ) {
    transactionId,
    tagId,
    startDate,
    payment {
      unit,
      startFee,
      kwhFee,
      idleFee,
      parkingFee,
      stripePaymentId
    }
  }
}
`;

export const GQL_QUERY_TRANSACTION_STATUS = gql`
query transactions($transactionId: Long!) {
  transactions(
    where: {
      and: [
        { transactionId: { eq: $transactionId } }
      ]
    }
  ) {
    transactionId,
    completed,
    endDate,
    meterStart,
    meterStop
  }
}
`;

export const GQL_QUERY_TRANSACTION_COSTS = gql`
query chargerTransactionCost($transactionId: Long!) {
  costs: chargerTransactionCost(transactionId: $transactionId) {
    unit,
    costs,
    consumptionKwh,
    amountCapturable
  }
}
`;

export const GQL_QUERY_RUNNING_TRANSACTION_BELONGS_TO_USER = gql`
query transactions($transactionId: Long!, $email: String!, $pincode: String!) {
  transactions(
    where: {
      and: [
        { transactionId: { eq: $transactionId } }
        { payment: { email: { eq: $email } } }
        { payment: { pincode: { eq: $pincode } } }
      ]
    }
  ) {
    transactionId
  }
}
`;