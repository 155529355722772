import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function pincodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const valid = control.value.every((d: string) => d !== '');
        if (valid) {
            return null;
        }
        return { reason: 'Not all digits provided' }
    }
}
   