export const environment = {
	production: false,
	build: {
		version: "0.0.8ab5e12e",
	},
	url: {
		api: "https://api.chargers-qa.lumosa.eu/graphql",
		cpo: "https://cpo-qa.lumosa.eu"
	},
	token: {
		cpo: "f6b66305-1b5c-4d77-ac23-d23ee67e550e",
		stripe: "pk_test_51NFcDrIVAamciNfYWXjvxMRAvKdbl5IeOVSQd35GA0VHFrqpxJKESY7bibgnyrp52Fep7vB95oPbWRcCjXDwISqL00W86RYVNz"
	},
	config: {
		stripe: {
			paymentTypes: [ 'card' ],
			reserve: {
				amount: 50,
				currency: 'EUR',
			},
		},
	},
}
