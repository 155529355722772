import { Injectable } from '@angular/core';
import { LdkJsonToPdfExportService, ListItem, PdfExportOptions, TreeItem } from '@lumosa/ui-sdk/services';
import jsPDF from 'jspdf';
import { StateService } from './state.service';

const DEFAULT_X_OFFSET = 15;
const DEFAULT_Y_OFFSET = 5;
const DEFAULT_INIT_Y_OFFSET = 10;

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends LdkJsonToPdfExportService {
  
  constructor(
    private state: StateService
  ) {
    super();
  }

  override convert(jsonData: Partial<TreeItem | ListItem>[], options?: PdfExportOptions): string | jsPDF {
    let body: any[] = [];
    jsonData.forEach((element: any) => {
      const item: any[] = [];
      options?.exportedFields.forEach(key => item.push(element[key]));
      body.push(item);
    });

    let yOffset = DEFAULT_INIT_Y_OFFSET;

    let doc = new jsPDF({
      orientation: 'landscape'
    });

    // add title
    doc.setFontSize(12);
    doc.text(`Invoice ${this.state.payment.id}`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += 3 * DEFAULT_Y_OFFSET;

    // add transaction info
    doc.setFontSize(10);
    doc.text(`Charger Info`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += DEFAULT_Y_OFFSET;
    doc.setFontSize(8);
    doc.text(`Charger: ${this.state.chargerForm.value.name}`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += DEFAULT_Y_OFFSET;
    doc.text(`Socket Id: ${this.state.chargerForm.value.connectorId}`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += DEFAULT_Y_OFFSET;
    doc.text(`Address: ${this.state.chargerForm.value.address}`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += 2 * DEFAULT_Y_OFFSET;

    doc.setFontSize(10);
    doc.text(`Transaction Info`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += DEFAULT_Y_OFFSET;
    doc.setFontSize(8);
    doc.text(`Payment Id: ${this.state.payment.id}`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += DEFAULT_Y_OFFSET;
    doc.text(`Session Id: ${this.state.transaction.id}`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += DEFAULT_Y_OFFSET;
    doc.text(`Consumption KWh: ${this.state.transaction.consumptionKwh}`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += DEFAULT_Y_OFFSET;
    doc.text(`Started on: ${this.state.transaction.startDate}`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += DEFAULT_Y_OFFSET;
    doc.text(`Ended on: ${this.state.transaction.endDate}`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += 2 * DEFAULT_Y_OFFSET;

    // Cost overview
    doc.setFontSize(10);
    doc.text(`Cost Overview`, DEFAULT_X_OFFSET, yOffset, {});
    yOffset += DEFAULT_Y_OFFSET;
    const autoTableConfig = {
      startY: yOffset,
      head: [options?.exportedFields],
      body
    };
    (doc as any).autoTable(autoTableConfig);

    // display total
    yOffset += 9 * DEFAULT_Y_OFFSET;
    doc.text(`Amount Paid: ${this.state.transaction.costs}`, DEFAULT_X_OFFSET, yOffset, {});

    return doc;
  }
}
