export interface Tariff {
    unit: string;
    kwhFee: number;
    parkingFee: number;
    startFee: number;
    idleFee: number;
    [key: string]: number | string;
}

export interface Payment {
    id: string;
    clientSecret: string;
    approved?: boolean;
}

export const DEFAULT_TARIFF: Tariff = {
    unit: "EUR",
    kwhFee: 1.0,
    parkingFee: 0,
    startFee: 1.0,
    idleFee: 0
}