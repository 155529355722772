import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LdkGraphqlApiService } from '@lumosa/ui-sdk/services';
import { NbToastrService } from '@nebular/theme';
import { debounceTime, tap, lastValueFrom, take, catchError } from 'rxjs';
import { GQL_QUERY_DIRECTPAY_PINCODE, GQL_QUERY_DIRECTPAY_SESSION_TOKEN } from 'src/app/graphql/gql.queries';
import { StateService, STORE_KEY_USER_REMEMBER_ME, STORE_KEY_USER_EMAIL } from 'src/app/services/state.service';

export const PINCODE_DIGIT_COUNT = 5;

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {
  @Input() form!: FormGroup;

  private pincodeArray = new Array(PINCODE_DIGIT_COUNT).fill('');
  isPincodeValid = false;
  pincodeDigits = [...Array(PINCODE_DIGIT_COUNT).keys()];

  rememberMe = false;

  constructor(
    public state: StateService,
    private graphqlApi: LdkGraphqlApiService,
    private notify: NbToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.rememberMe = (localStorage.getItem(STORE_KEY_USER_REMEMBER_ME) || 'false') === 'true' ? true : false;
    this.form.valueChanges
      .pipe(
        debounceTime(1000),
        tap(x => {
          if (this.rememberMe) {
            localStorage.setItem(STORE_KEY_USER_EMAIL, x.email);
          }
        })
      )
      .subscribe();
    
    this.route.queryParams
      .pipe(
        tap(params => {
          const { t = '<EMPTY>', c = '1', p = '', u = '' } = params;
          this.state.chargerForm.patchValue({
            connectorId: c,
            typeplate: t
          });
          if (!!p) {
            this.state.credentialsForm.patchValue({ pincode: p.split('') });
          }
          if (!!u) {
            this.state.credentialsForm.patchValue({ email: u });
          }
        })
      )
      .subscribe();
  }

  onDigitKeyup(event: any, index: number){
    if (Number.isInteger(+event.key)) {
      const nextDigit = event.target.nextElementSibling;
      if (nextDigit) {
        nextDigit.focus();
      } else {
        event.target.blur();
      }
      this.pincodeArray[index] = event.target.value;
      this.validatePincode();

    } else if(event.code === 'Backspace') {
      const previousDigit = event.target.previousElementSibling;
      if (previousDigit) {
        previousDigit.focus();
        previousDigit.value = '';
      }
    }
  }

  onDigitFocus(event: any, index: number) {
    event.target['value'] = '';
    this.pincodeArray[index] = '';
    this.validatePincode();
  }

  private validatePincode() {
    this.form.patchValue({ pincode: this.pincodeArray });
  }

  rememberUser(value: boolean) {
    localStorage.setItem(STORE_KEY_USER_EMAIL, value ? this.state.credentialsForm.value.email: '');
    localStorage.setItem(STORE_KEY_USER_REMEMBER_ME, value.toString());
  }

  async requestPincode() {
    this.state.isLoading = true;
    const o$ = this.graphqlApi.query<string>({
      query: GQL_QUERY_DIRECTPAY_PINCODE,
      fetchPolicy: 'no-cache',
      variables: {
        typePlateId: this.state.chargerForm.value.typeplate,
        connectorId: +this.state.chargerForm.value.connectorId,
        email: this.state.credentialsForm.value.email,
      },
      returnedAsName: 'token'
    })
    .pipe(
      take(1),
      tap(_ => {
        this.state.isLoading = false;
        this.notify.success(`Pincode has been sent to ${this.state.credentialsForm.value.email}`, 'Pincode');
        this.pincodeArray = new Array(PINCODE_DIGIT_COUNT).fill('');
        this.validatePincode();
      }),
      catchError(e => {
        this.state.isLoading = false;
        throw e;
      })
    );
    return lastValueFrom(o$);
  }

  async requestApiToken(): Promise<string> {
    this.state.isLoading = true;
    const o$ = this.graphqlApi.query<string>({
      query: GQL_QUERY_DIRECTPAY_SESSION_TOKEN,
      fetchPolicy: 'no-cache',
      variables: {
        typePlateId: this.state.chargerForm.value.typeplate,
        connectorId: +this.state.chargerForm.value.connectorId,
        email: this.state.credentialsForm.value.email,
        pincode: (this.state.credentialsForm.value.pincode || []).join('')
      },
      returnedAsName: 'token'
    })
    .pipe(
      take(1),
      tap(_ => this.state.isLoading = false),
      catchError(e => {
        this.state.isLoading = false;
        throw e;
      })
    );
    return lastValueFrom(o$);
  }
}
