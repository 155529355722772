import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbStepperModule, NbCardModule, NbButtonModule, NbLayoutModule, NbInputModule, NbUserModule, NbListModule, NbSpinnerModule, NbDialogModule, NbCheckboxModule, NbToastrModule, NbSelectModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ReactiveFormsModule } from '@angular/forms';
import { ApolloModule } from 'apollo-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GRAPHQL_API_URL } from '@lumosa/ui-sdk/services';
import { LdkNotFoundCardModule } from '@lumosa/ui-sdk/components/not-found';
import { LdkDialogsModule } from '@lumosa/ui-sdk/components/dialogs';
import { LdkPipesModule } from '@lumosa/ui-sdk/pipes';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { CredentialsComponent } from './components/credentials/credentials.component';
import { HomeComponent } from './components/home/home.component';
import { PaymentComponent } from './components/payment/payment.component';
import { TariffComponent } from './components/tariff/tariff.component';
import { ChargerComponent } from './components/charger/charger.component';
import { environment } from 'src/environments/environment';

const ldkModules = [
  LdkNotFoundCardModule,
  LdkDialogsModule,
  LdkPipesModule
];

const nebularModules = [
  NbThemeModule.forRoot({ name: 'default' }),
  NbEvaIconsModule,
  NbStepperModule,
  NbCardModule,
  NbButtonModule,
  NbLayoutModule,
  NbInputModule,
  NbUserModule,
  NbListModule,
  NbSpinnerModule,
  NbDialogModule.forRoot(),
  NbToastrModule.forRoot(),
  NbCheckboxModule,
  NbSelectModule
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CredentialsComponent,
    PaymentComponent,
    TariffComponent,
    ChargerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ApolloModule,
    ...nebularModules,
    ...ldkModules
  ],
  providers: [
    {
      provide: GRAPHQL_API_URL,
      useValue: environment.url.api //'https://192.168.179.190:5000/graphql'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
