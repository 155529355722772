import { Component, Input, OnInit } from '@angular/core';
import { StateService } from 'src/app/services/state.service';
import { IMAGE_CHARGER, IMAGE_LOCATION } from '../home/images';

@Component({
  selector: 'app-charger',
  templateUrl: './charger.component.html',
  styleUrls: ['./charger.component.scss']
})
export class ChargerComponent implements OnInit {
  @Input() isPublicCharger = false;

  images = {
    location: IMAGE_LOCATION,
    charger: IMAGE_CHARGER
  }

  constructor(
    public state: StateService
  ) { }

  ngOnInit(): void {
  }

  goToMap() {
    window.open(`https://www.google.com/maps/place/${this.state.chargerForm.value.address}`, "_blank");
  }
}
