<label class="label">Tariff</label>
<nb-card>
    <nb-list>
        <nb-list-item *ngFor="let t of tariffs">
            <nb-user
                shape="semi-round"
                [title]="t.title"
                name="{{ t.value }} {{ t.unit }}" 
                [picture]="t.image">
            </nb-user>
        </nb-list-item>
    </nb-list>
</nb-card>

<ng-container *ngIf="!state.transaction.id">
    <label class="label">Reserve Amount</label>
    <nb-select 
        style="margin-bottom: 10px;" 
        fullWidth placeholder="Amount" 
        [selected]="state.selectedAmount" 
        (selectedChange)="selectedAmountChange($event)">
            <nb-option *ngFor="let o of amountOptions" [value]="o">{{ o }} {{ state.tariff.unit }}</nb-option>
    </nb-select>
</ng-container>

