import { Component, OnInit } from '@angular/core';
import { IMAGE_CHARGING, IMAGE_LOCATION, IMAGE_PARKING, IMAGE_START } from '../home/images';
import { StateService } from 'src/app/services/state.service';
import { Tariff } from 'src/app/models/tariff.model';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.scss']
})
export class TariffComponent implements OnInit {
  chargerImage = IMAGE_LOCATION;
  chargerAddress = '';

  tariffs: { title: string, value: number, unit: string, id: string, image: string }[] = [
    { title: 'Start Fee', value: 0.75, unit: 'EURO', id: 'startFee', image: IMAGE_START },
    { title: 'Kwh Fee', value: 1.0, unit: 'EURO', id: 'kwhFee', image: IMAGE_CHARGING },
    // { title: 'Idle Fee', value: 0, unit: 'EURO', id: 'idleFee', image: IMAGE_IDLE },
    { title: 'Parking Fee', value: 0, unit: 'EURO', id: 'parkingFee', image: IMAGE_PARKING },
  ];
  
  amountOptions = [25, 50, 100, 150];

  constructor(
    public state: StateService,
  ) { }

  ngOnInit(): void {
  }

  updateTariffOverview(tariff: Tariff) {
    this.state.tariff = {
      ...this.state.tariff,
      ...tariff
    }
    const unit = tariff.unit;
    Object.keys(tariff).filter(key => key !== 'unit').forEach((key: string) => {
      const data = this.tariffs.find(t => t.id === key);
      if (data) {
        const value = tariff[key] as number;
        data.unit = unit;
        data.value = value;
      }
    });
  }

  selectedAmountChange(value: number) {
    this.state.selectedAmount = value;
  }
}
