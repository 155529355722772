<ng-container *ngIf="!state.transaction.id">
    <label class="label">Payment</label>
    <div id="payment-element">
        <!-- Elements will create form elements here -->
    </div>
    <button
        nbButton 
        *ngIf="!isLoadingStripeElements"
        style="margin-top: 10px;"
        status="success" 
        (click)="startTransaction()">
            Start
    </button>
</ng-container>

<ng-container *ngIf="state.transaction.id">
    <label class="label">Session</label>
    <nb-card>
        <nb-list-item>
            <nb-user
                shape="semi-round"
                title="Estimated Costs"
                name="{{ state.transaction.costs }}"
                [picture]="imageMoney">
            </nb-user>
        </nb-list-item>
        <nb-list-item>
            <nb-user
                shape="semi-round"
                title="Usage KWh"
                name="{{ state.transaction.consumptionKwh }}"
                [picture]="imageUsage">
            </nb-user>
        </nb-list-item>
        <nb-list-item>
            <nb-user
                shape="semi-round"
                title="Started on"
                name="{{ state.transaction.startDate | ldkUtcToLocalTime }}"
                [picture]="imageStart">
            </nb-user>
        </nb-list-item>
        <ng-container *ngIf="this.state.transaction.isCompleted">
            <nb-list-item>
                <nb-user
                    shape="semi-round"
                    title="Terminated on"
                    name="{{ state.transaction.endDate | ldkUtcToLocalTime }}"
                    [picture]="imageStop">
                </nb-user>
            </nb-list-item>
        </ng-container>
    </nb-card>
    <div *ngIf="!this.state.transaction.isCompleted" style="display: flex; flex-direction: row; gap: 10px">
        <button
            nbButton status="danger" 
            (click)="stopTransaction()">
                Stop
        </button>
        <button
            nbButton 
            status="basic" 
            (click)="refresh()">
                Refresh
        </button>
    </div>
    <button
        *ngIf="this.state.transaction.isCompleted"
        nbButton 
        status="danger"
        (click)="createInvoice()">
            Invoice
    </button>
</ng-container>    

